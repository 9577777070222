// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-templates-blog-js": () => import("./../../../src/templates/blog.js" /* webpackChunkName: "component---src-templates-blog-js" */),
  "component---src-templates-contact-js": () => import("./../../../src/templates/contact.js" /* webpackChunkName: "component---src-templates-contact-js" */),
  "component---src-templates-cv-js": () => import("./../../../src/templates/cv.js" /* webpackChunkName: "component---src-templates-cv-js" */),
  "component---src-templates-education-js": () => import("./../../../src/templates/education.js" /* webpackChunkName: "component---src-templates-education-js" */),
  "component---src-templates-error-js": () => import("./../../../src/templates/error.js" /* webpackChunkName: "component---src-templates-error-js" */),
  "component---src-templates-home-js": () => import("./../../../src/templates/home.js" /* webpackChunkName: "component---src-templates-home-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */),
  "component---src-templates-project-js": () => import("./../../../src/templates/project.js" /* webpackChunkName: "component---src-templates-project-js" */),
  "component---src-templates-qualification-js": () => import("./../../../src/templates/qualification.js" /* webpackChunkName: "component---src-templates-qualification-js" */),
  "component---src-templates-redirect-js": () => import("./../../../src/templates/redirect.js" /* webpackChunkName: "component---src-templates-redirect-js" */),
  "component---src-templates-showcase-js": () => import("./../../../src/templates/showcase.js" /* webpackChunkName: "component---src-templates-showcase-js" */),
  "component---src-templates-work-js": () => import("./../../../src/templates/work.js" /* webpackChunkName: "component---src-templates-work-js" */)
}

