/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

exports.onClientEntry = () => {
  // console.log("We've started!")
  window.global = window;
  global.Buffer = global.Buffer || require("buffer").Buffer;
  // (window).process = {
  //   version: ''
  // };

  window.process = {
    env: { DEBUG: undefined },
    nextTick: function () {
      return null;
    },
  };
};

exports.onInitialClientRender = () => {
  if (
    "onGatsbyInitialClientRender" in window &&
    typeof window.onGatsbyInitialClientRender === "function"
  ) {
    window.onGatsbyInitialClientRender();
  }
};

exports.onRouteUpdate = () => {
  if (
    "onGatsbyRouteUpdate" in window &&
    typeof window.onGatsbyRouteUpdate === "function"
  ) {
    window.onGatsbyRouteUpdate();
  }
};
